const Rules = () => {
    return(
        <section className='container'>
            <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                Règlement :
            </h2>
            <p className='color-green font-size-2'>
                Bienvenue à toi Soldat !<br />
                Je me présente je suis le Lieutenant Wyatt Handerson officier en charge de ce régiment. 
                Ici tu trouveras la liste de règle de vie dans le camp du 1er Régiment de Chasseurs Parachutistes.
            </p>
            <ul className='color-green font-size-2'>
                <li className="margin-top-2">
                    Les opérations et les modpacks qui servent pour les OP (Opérations événement une fois par semaine) sont envoyés 
                    dans ⁠”sondages” sur le Discord et il faut réagir avec la date que tu souhaites pour dire que tu es présent. Une fois 
                    réagit tu es comptabilisé dans les rangs pour l'opération ( conseil : ne t'enregistres pas si tu ne comptes pas venir ). En 
                    cas d'absence pour une OP je préviens le Lieutenant Wyatt Handerson.
                </li>
                <li className="margin-top-2">
                    Concernant les absences longues durées il faut aussi prévenir par un petit message privé même si on ne sait pas sa 
                    date de retour.
                </li>
                <li className="margin-top-2">
                    La présence est non obligatoire , mais il reste quand même primordiale de prévenir et tenir au courant pour éviter des 
                    annulations d'OP ou de rassemblement car individuellement en ne prévenant pas on va bloquer des personnes qui se 
                    sont peut être privées d'une sortie , d'un évènements extérieures . Sans parler de manquement de respect par 
                    rapport au travail fournis par les personnes qui crées des scénarios pour que l'on puisse y jouer... Donc le minimum 
                    c'est de prévenir tu le fais tous les jours pour des rendez vous , au travail , à l'école etc ... alors une fois en plus ça va 
                    pas nous faire de mal.
                </li>
                <li className="margin-top-2">
                    Obéir aux ordres des gradés sauf si l'ordre est contraire à la logique (ordre de tuer un coéquipier , ordre de foncer droit 
                    sur une mitrailleuse sans vrai tactique).
                </li>
                <li className="margin-top-2">
                    Il peut y avoir des entrainements ponctuels comme le reste ABSENCE = PREVENIR , les entrainements sont là pour 
                    avoir des réflexes , apprendre de nouvelles techniques et comblés des failles repérées en plus ce sont des moments 
                    détentes dans le régiment.
                </li>
            </ul>
            <p className='color-green font-size-2 margin-top-2'>
                Bref tu l'auras compris pas de prise de tête soldat , juste prévenir en cas d'absence peu importe la situation.
            </p>
        </section>
    )
}

export default Rules;