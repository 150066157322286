import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import minescarm from '../img/medias/minescarm.webp';
import uria from '../img/medias/uria.webp';
import rainbowcast from '../img/medias/rainbowcast.webp';
import bilto from '../img/medias/bilto.webp'
import guizzarm from '../img/medias/guizzarm.webp';
import sedatif from '../img/medias/sedatif.webp'

const Media = () => {
    const apiKey =
        process.env.REACT_APP_API_KEY;

    return(
        <div>
            <section className='container'>
            <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                    Instagram :
                </h2>
                <InstagramFeed
                    token={apiKey}
                    counter="24"
                />
            </section>

            <section className='container'>
                <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                    Twitch :
                </h2>

                <div className='row gap-4 margin-bottom-3'>
                    <div className='col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.twitch.tv/minescarm' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img  src={minescarm} alt='Twitch de MineScarm' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>MineScarm</h3>
                        </a>
                    </div>
                    <div className='col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.twitch.tv/the_uria_livestream' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img  src={uria} alt='Twitch de The_Uria_Livestream' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>The_Uria_Livestream</h3>
                        </a>
                    </div>
                    <div className='col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.twitch.tv/rainbowcasttv' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img  src={rainbowcast} alt='Twitch de rainbowcastTV' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>rainbowcastTV</h3>
                        </a>
                    </div>
                    <div className='col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.twitch.tv/bilto50' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img src={bilto} alt='Twitch de bilto50' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>bilto50</h3>
                        </a>
                    </div>
                </div>
                
                <div className='row gap-4 margin-bottom-3'>
                    <div className='col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.twitch.tv/sedatif_fr' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img src={sedatif} alt='Twitch de sedatif_fr' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>sedatif_fr</h3>
                        </a>
                    </div>
                    <div className='col'>

                    </div>
                    <div className='col'>

                    </div>
                    <div className='col'>

                    </div>
                </div>
            </section>
            
            <section className='container'>
                <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                    Youtube :
                </h2>

                <div className='row gap-4 margin-bottom-3'>
                    <div className='col-sm-12 col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>
                        <a target='blank' href='https://www.youtube.com/@guizzarmstudiofrenchmine400' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img  src={guizzarm} alt='Youtube de French & Mine' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>GUIZZ'ARM STUDIO</h3>
                        </a>  
                    </div>
                    <div className='col-sm-12 col-md width-60 background-brown border-black margin-left-20-mobile padding-0 card-shadow-hov opacity-09 opacity-1-hov'>   
                        <a target='blank' href='https://www.youtube.com/channel/UCfuzZtbX6yU7UpGWcKwR51g' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img  src={uria} alt='Youtube de The_Uria_Livestream' className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>The_Uria_Livestream</h3>
                        </a>
                    </div>
                    <div className='col'>

                    </div>
                    <div className='col'>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Media;