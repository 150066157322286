import { Link } from 'react-router-dom';
import presentation from '../img/presentation.webp';
import eagle from '../img/spe/eagle.webp';
import india from '../img/spe/india.webp';
import bravo from '../img/spe/bravo.webp';

const Home = () => {
    return(
        <div>
            <section className='container padding-top-3 padding-bottom-3'>
                <div className='row'>
                    <p className='col-sm-12 col-md font-size-2 color-green'>
                        Le 1 RCP est une team de simulation militaire dont l’expérience au fil du temps s’est construite.<br />
                        Une équipe accueillante et qui s’adapte aussi bien pour les nouveaux joueurs qu’aux anciens.<br />
                        Détendu mais avec un savoir faire qui se perfectionne au fil des sessions avec des retours et une écoute des critiques.
                    </p>
                    <div className='col-sm-12 col-md'>
                        <img className='border-black width-100' src={presentation} fetchpriority="high" alt='Présentation du 1RCP' />
                    </div>
                </div>
            </section>

            <section className='container'>
                <h2 className='border-bottom-black font-size-2-5 padding-top-3 padding-bottom-1 margin-bottom-1 color-black'>
                    Nos spécialisations :
                </h2>
                
                <div className='row gap-4'>
                    <div className='col-md width-60 background-brown border-black card-shadow-hov opacity-09 opacity-1-hov margin-left-20-mobile padding-0'>
                        <Link to='/india' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img src={india} alt="India" className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>Infanterie : India</h3>
                        </Link>
                    </div>
                    <div className='col-md width-60 background-brown border-black card-shadow-hov opacity-09 opacity-1-hov margin-left-20-mobile padding-0'>
                        <Link to='/eagle' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img src={eagle} alt="Eagle" className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>Aérien : Eagle</h3>
                        </Link>
                    </div>
                    <div className='col-md width-60 background-brown border-black card-shadow-hov opacity-09 opacity-1-hov margin-left-20-mobile padding-0'>
                        <Link to='/bravo' className='color-green decoration-none decoration-underline-hov color-green-hov text-center'>
                            <img src={bravo} alt="Bravo" className='width-100' />
                            <h3 className='font-size-2 padding-top-3 padding-bottom-3'>Blindé : Bravo</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;