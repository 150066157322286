const Join = () => {
    return(
        <section>
            <div className='container'>
                <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                    S'engager :
                </h2>
                <p className='color-green font-size-2'>
                    L’armée recrute et forme 16000 postes et 5000 réservistes !<br />

                    Blague mise à part, le 1er Régiment de Chasseurs Parachutistes est toujours à la recherche de nouveaux profils dans 
                    toutes nos Spécialisations.<br />

                    Alors, que vous soyez débutants ou un anciens d'Arma, n’hésitez plus et cliquez sur “Je m’engage” !
                </p>
            </div>
            <a className='join-button margin-top-6 margin-bottom-3 border-desktop border-mobile' target="blank" href="https://discord.gg/c8xk27TCB3">JE M'ENGAGE !</a>
            <div className="padding-top-50-mobile"></div>
        </section>
    )
}

export default Join;