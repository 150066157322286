import { Link } from 'react-router-dom';
import indiaImg from '../img/spe/india-spe.webp';

const India = () => {
    return(
        <section className='container'>
            <h2 className='border-bottom-black font-size-2-5 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                India :
            </h2>

            <div className='row'>
                <p className='col-sm-12 col-md font-size-2 color-green'>
                    L'unité India est constituée de combattants d'infanterie regroupant plusieurs tactiques et 
                    équipements dont l'artillerie , la reconnaissance et bien d'autres ... Elle réalise les missions
                    qui lui sont confiées en interaction avec 
                    <Link to='/bravo' className='color-green-hov color-black'> Bravo </Link> 
                    et 
                    <Link to='/eagle' className='color-green-hov color-black'> Eagle </Link> 
                    dans l'accomplissement d'objectifs en combat inter arme ils ont des moyens tels que des véhicules
                    léger , des armes statiques et bien d'autres équipements afin de réaliser et atteindre leurs
                    objectifs fixés.
                </p>
                <div className='col-sm-12 col-md'>
                    <img src={indiaImg} alt='India en action' className='border-black width-100' />
                </div>
            </div>

            <h2 className='border-bottom-black font-size-2-5 padding-top-3 padding-bottom-1 margin-top-3 margin-bottom-1 color-black'>
                Sous spécialisations :
            </h2>

            <div className='row font-size-1-5'>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Reconnaissance :</h3>
                    <ul className='color-green'>
                        <li>Tireur d'élite</li>
                        <li>Opérateur drone et satellites</li>
                        <li>Plongeur de combat</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Artillerie :</h3>
                    <ul className='color-green'>
                        <li>Artilleur léger</li>
                        <li>Artilleur lourd</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Génie :</h3>
                    <ul className='color-green'>
                        <li>Ingénieur infrastructures et opérationnel</li>
                        <li>Logisticien</li>
                        <li>Génie du milieu</li>
                    </ul>
                </div>
            </div>
            <div className='row font-size-1-5'>
                <div className='col-sm-6 col-md-4'>
                    <h3 className='font-size-2-5'>Fantassin :</h3>
                    <ul className='color-green'>
                        <li>Maitre de saut</li>
                        <li>Infirmier opérationnel terrain</li>
                        <li>Opérateur radio</li>
                        <li>Tireur minimi</li>
                        <li>Grenadier</li>
                        <li>Tireur de précision</li>
                        <li>Anti-tank</li>
                    </ul>
                </div>

                <div className='col-sm-6 col-md-4'></div>
                <div className='col-sm-6 col-md-4'></div>
                
            </div>
        </section>
    )
}

export default India;